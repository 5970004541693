import Vue from 'vue'
import Toast from 'vue-toastification'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
  hideProgressBar: true,
  closeOnClick: false,
  closeButton: false,
  icon: false,
  timeout: 3000,
  transition: 'Vue-Toastification__fade',
})

export const toastSuccess = (text, title = 'Berhasil') => {
  Vue.$toast(
    {
      component: ToastificationContentVue,
      props: {
        title,
        text,
        variant: 'success',
        icon: 'CheckIcon',
      },
    },
    { timeout: 2500 },
  )
}

export const toastError = (text, title = 'Gagal') => {
  Vue.$toast(
    {
      component: ToastificationContentVue,
      props: {
        title,
        text,
        variant: 'danger',
        icon: 'AlertCircleIcon',
      },
    },
    { timeout: 2500 },
  )
}
